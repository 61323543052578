/**
 * SliderCardArticle
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SlickSlider from 'Components/SlickSlider';
import CardArticle from 'Components/CardArticle';
import { useStyle } from 'Themes/theme';

import sD from './SliderCardArticle.Dansskolanentre.module.scss';
import sK from './SliderCardArticle.Kulturama.module.scss';
import sM from './SliderCardArticle.Medborgarskolan.module.scss';
import sP from './SliderCardArticle.Pahlmans.module.scss';
import sT from './SliderCardArticle.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const SliderCardArticle = ({
    items = [],
    title = '',
    noTopSpacing = false,
    className = '',
}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['SliderCardArticle'],
        {[s['SliderCardArticle--NoTopSpacing']]: noTopSpacing},
        className,
    );

    return (
        <div className={classes}>
            <div className={s['SliderCardArticle__Container']}>
                <SlickSlider
                    Component={CardArticle}
                    items={items}
                    title={title}
                    mobileInset="50px"
                />
            </div>
        </div>
    );
};

SliderCardArticle.defaultProps = {
    items: PropTypes.array,
    title: PropTypes.string,
    noTopSpacing: PropTypes.bool,
};

export default SliderCardArticle;
