/**
 * SubjectList
 */

import React, { useState, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import BaseContext from 'Layouts/BasePage/BaseContext';
import Image from 'Components/Image';
import { useStyle } from 'Themes/theme';

import sK from './SubjectList.Kulturama.module.scss';
import sM from './SubjectList.Medborgarskolan.module.scss';
import sP from './SubjectList.Pahlmans.module.scss';

const styles = {
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const SubjectList = ({
    items = [],
    modifier = '',
    isExpandable = false, // set to true to show the "show more" button
    noTopSpacing = false,
}) => {
    const { t } = useTranslation();

    const s = useStyle(styles);

    const [hideItems, setHideItems] = useState(items.length > 8);

    const handleClick = () => {
        setHideItems(!hideItems);
    };

    const classes = classNames(
        s['SubjectList'],
        {[s[`SubjectList--${modifier}`]]: modifier },
        {[s['SubjectList--Hide']]: hideItems},
        {[s['SubjectList--All']]: !hideItems},
        {[s['SubjectList--NoTopSpacing']]: noTopSpacing},
    );

    if (_.isEmpty(items)) {
        return null;
    }

    return (
        <div className={classes}>
            <div className={s['SubjectList__Container']}>
                <h2 className={s['SubjectList__Title']}>{t('subjectList.title')}</h2>
                <div className={s['SubjectList__Categories']}>
                    {items.map((item, index) => (
                        <Subject
                            key={index}
                            item={item}
                            index={index}
                            hideItems={isExpandable ? hideItems : !hideItems}
                        />
                    ))}
                </div>
                {isExpandable && (
                    <button
                        className={s['SubjectList__Button']}
                        onClick={() => handleClick()}
                    >
                        {t('subjectList.buttonTextMore')}
                    </button>
                )}
            </div>
        </div>
    );
};

const Subject = ({
    item = {},
    index = 0,
    hideItems = true,
}) => {
    const {currentTheme} = useContext(BaseContext);

    const s = useStyle(styles);

    const hideXS = index > 3 && hideItems;
    const hideSM = index > 7 && hideItems;

    const isIcon = !_.isEmpty(item?.icon);
    const isImage = !isIcon && !_.isEmpty(_.get(item.image, 'src', ''));

    const imageSizes = currentTheme === 'Kulturama' ? [
        '(min-width: 1280px) 281px',
        '(min-width: 960px) 25vw',
        '50vw'
    ] : ['64px'];

    const classes = classNames(
        s['SubjectList__Category'],
        {[s['SubjectList__Category--HiddenXS']]: hideXS},
        {[s['SubjectList__Category--HiddenSM']]: hideSM},
        {[s['SubjectList__Category--IsIcon']]: isIcon},
        {[s['SubjectList__Category--IsImage']]: isImage},
    );

    return (
        <LazyLoad height={160} once={true} className={classes}>
            <article className={s['SubjectList__CategoryWrapper']}>
                <a
                    className={s['SubjectList__Link']}
                    title={item.link && item.link.title}
                    href={item.link && item.link.href}
                >
                    {isImage && (
                        <div className={s['SubjectList__ImageContainer']}>
                            <Image
                                {...item.image}
                                className={s['SubjectList__Image']}
                                sizes={imageSizes}
                                useCover={true}
                            />
                        </div>
                    )}

                    {isIcon && (
                        <div className={s['SubjectList__ImageContainer']}>
                            <img
                                className={s['SubjectList__Icon']}
                                src={`/img/wagtail-icons/subjects/${item.icon}`}
                                alt=""
                                width="44"
                                height="44"
                                loading="lazy"
                            />
                        </div>
                    )}

                    <h3
                        data-title={item.title}
                        className={s['SubjectList__LinkTitle']}
                    >
                        {item.title}
                    </h3>
                </a>
            </article>
        </LazyLoad>
    );
};

export default SubjectList;
