/**
 * EventSearchInput
 */

import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import _ from 'lodash';
import {getCookie, setCookie, isCookieAllowed} from 'utils/Cookie';
import BaseContext from 'Layouts/BasePage/BaseContext';
import EventSearchInputField from './EventSearchInputField';
import { useStyle } from 'Themes/theme';

import sK from './EventSearchInput.Kulturama.module.scss';
import sM from './EventSearchInput.Medborgarskolan.module.scss';
import sP from './EventSearchInput.Pahlmans.module.scss';

const styles = {
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const EventSearchInput = ({
    q = '',
    searchField = true,
    type = '',
    subjects = [],
    defaultSubject = false,
    cities = [],
    modifier = '',
    filterCity = '',
    filterSubject = '',
    suggestApi = '',
    suggestions = [],
    qHandler = null,
    cityHandler = null,
    subjectHandler = null,
    keywordHandler = null,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const {cookiePopup} = useContext(BaseContext);

    const [currFilterCity, setCurrFilterCity] = useState(filterCity || undefined);
    const [currFilterSubject, setCurrFilterSubject] = useState(String(filterSubject) || undefined);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const city = decodeURIComponent(getCookie('filter-city'));

        // Only use cookie as city if it hasn't been set by another way
        if(city !== '' && city !== currFilterCity && !filterCity) {
            setCurrFilterCity(city);

            if(cityHandler) {
                cityHandler(city);
            }
        }

        setLoaded(true);
    }, []);

    useEffect(() => {
        // Change state of city if changed from container by popstate
        if(filterCity !== undefined && filterCity !== currFilterCity) {
            setCurrFilterCity(filterCity);
        }
    }, [filterCity]);

    const setCityFilter = (selected) => {
        const value = selected ? selected.value : '';

        if(isCookieAllowed(cookiePopup, 'filter-city')) {
            setCookie('filter-city', encodeURIComponent(value));
        }

        setCurrFilterCity(value);
        if(cityHandler) {
            cityHandler(value);
        }
    };

    const setSubjectFilter = (selected) => {
        let value = selected ? selected.value : undefined;
        if(value === undefined && defaultSubject) {
            value = String(defaultSubject);
        }

        setCurrFilterSubject(value);
        if(subjectHandler) {
            subjectHandler(value);
        }
    };

    const subjectVal = String(currFilterSubject) || String(defaultSubject) || '';
    const cityGroups = [...cities].filter(
        ({ value, options }) => value || (options && options.length > 0)
    ).map((item) => {
        if(item?.options) {
            return item.options;
        } else {
            // For distance which isn't a group
            return item;
        }
    });
    const searchCities = cityGroups.length > 0 ? _.flatten(cityGroups) : cities;

    const hasSubjects = subjects.length > 0;
    const hasCities = cities.length > 0;

    // If cities is the only selection
    const singular = !searchField && !hasSubjects;

    const currSubjectObj = _.isEmpty(subjectVal) ? null : [...subjects].find(
        ({value}) => value === subjectVal
    );
    const currCityObj = _.isEmpty(currFilterCity) ? null : [...searchCities].find(
        ({value}) => value === currFilterCity
    );

    const classes = classNames(
        s['EventSearchInput'],
        {[s[`EventSearchInput--${modifier}`]]: modifier},
        {[s['EventSearchInput--Search']]: searchField},
        {[s['EventSearchInput--Singular']]: singular},
        {[s['EventSearchInput--OnlySearch']]: searchField && !hasSubjects && !hasCities},
    );

    return (
        <div className={classes}>
            <div className={s['EventSearchInput__Container']}>
                <div className={s['EventSearchInput__InputContainer']}>
                    {searchField &&
                        <div className={s['EventSearchInput__InputField']}>
                            <EventSearchInputField
                                q={q}
                                type={type}
                                suggestions={suggestions}
                                api={suggestApi}
                                modifier={modifier}
                                qHandler={qHandler}
                                keywordHandler={keywordHandler}
                            />
                        </div>
                    }
                    {hasSubjects &&
                        <label className={s['EventSearchInput__SelectContainer']}>
                            <span className="sr-only">{t('eventSearchInput.labelSubject')}</span>
                            {loaded &&
                                <ReactSelect
                                    value={currSubjectObj}
                                    options={subjects}
                                    name="subject"
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={setSubjectFilter}
                                    className="Select"
                                    classNamePrefix="Select"
                                    placeholder={t('eventSearchInput.labelSubject')}
                                    noResultsText={t('eventSearchInput.noOptionsFound')}
                                    inputProps={{
                                        autoComplete: 'off',
                                        autoCorrect: 'off',
                                        spellCheck: 'false',
                                    }}
                                />
                            }
                        </label>
                    }
                    {hasCities &&
                        <label className={s['EventSearchInput__SelectContainer']}>
                            <span className="sr-only">{t('eventSearchInput.labelCity')}</span>
                            {loaded &&
                                <ReactSelect
                                    value={currCityObj}
                                    options={cities}
                                    matchPos="start"
                                    name="city"
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={setCityFilter}
                                    className="Select"
                                    classNamePrefix="Select"
                                    placeholder={t('eventSearchInput.labelCity')}
                                    noResultsText={t('eventSearchInput.noOptionsFound')}
                                    inputProps={{
                                        autoComplete: 'off',
                                        autoCorrect: 'off',
                                        spellCheck: 'false',
                                    }}
                                    // menuIsOpen={true} // For debugging
                                />
                            }
                        </label>
                    }
                </div>
                <button
                    type="submit"
                    className={s['EventSearchInput__Button']}
                    aria-label={t('eventSearchInput.submit')}
                >
                    <span className={s['EventSearchInput__ButtonText']}>{t('eventSearchInput.submit')}</span>
                </button>
            </div>
        </div>
    );
};

export default EventSearchInput;
