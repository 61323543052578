/**
 * PageHome
 */

import React from 'react';
import _ from 'lodash';
import { basePageWrap } from 'Layouts/BasePage';
import Hero from 'Components/Hero';
import SubjectList from 'Components/SubjectList';
import SliderCardArticle from 'Components/SliderCardArticle';
import CalendarList from 'Components/CalendarList';
import SliderCardArticleHalf from 'Components/SliderCardArticleHalf';
import Billboard from 'Components/Billboard';
import Notification from 'Components/Notification';
import SliderCardNews from 'Components/SliderCardNews';
import { useStyle } from 'Themes/theme';

import sM from './PageHome.Medborgarskolan.module.scss';

const styles = {
    Medborgarskolan: sM,
};

const PageHome = ({
    hero,
    eventSearchInput,
    subjectList,
    sliderCardArticle,
    calendarSlider,
    sliderCardArticleHalf,
    billboard,
    notification,
    newsList,
}) => {
    const s = useStyle(styles);

    const hasSubjectList = !_.isEmpty(_.get(subjectList, 'items', []));
    const hasBillboard = !_.isEmpty(billboard);
    const hasNews = !_.isEmpty(_.get(newsList, 'items', []));
    const hasArticles = !_.isEmpty(_.get(sliderCardArticle, 'items', []));
    const hasCalendar = !_.isEmpty(calendarSlider) && (Object.keys(calendarSlider.items).length > 0);

    const newsHasTopSpacing = hasBillboard || !hasSubjectList;
    const articleHasTopSpacing = !hasNews && newsHasTopSpacing;
    const halfHasTopSpacing = hasCalendar || (!hasArticles && articleHasTopSpacing);

    return (
        <div className={s['PageHome']}>
            <Hero
                {...hero}
                eventSearchInput={eventSearchInput}
                modifier="PageHome"
            />
            {notification &&
                <Notification {...notification} />
            }
            {hasSubjectList &&
                <SubjectList
                    {...subjectList}
                />
            }
            {hasBillboard &&
                <Billboard {...billboard} />
            }
            {hasNews &&
                <SliderCardNews {...newsList} noTopSpacing={!newsHasTopSpacing} />
            }
            {hasArticles &&
                <SliderCardArticle {...sliderCardArticle} noTopSpacing={!articleHasTopSpacing} />
            }
            {hasCalendar &&
                <CalendarList
                    {...calendarSlider}
                    modifier="Home"
                />
            }
            {sliderCardArticleHalf.items &&
                <SliderCardArticleHalf {...sliderCardArticleHalf} noTopSpacing={!halfHasTopSpacing} />
            }
        </div>
    );
};

PageHome.defaultProps = {
    hero: {},
    eventSearchInput: {},
    subjectList: {},
    calendarList: {},
    sliderCardArticle: {},
    sliderCardArticleHalf: {},
    billboard: {},
    newsList: {},
};

export default basePageWrap(PageHome);
