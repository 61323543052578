/**
 * EventSearchInputField
 */

import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import _ from 'lodash';
import {httpGet} from 'utils/Http';
import { useStyle } from 'Themes/theme';

import sK from './EventSearchInputField.Kulturama.module.scss';
import sM from './EventSearchInputField.Medborgarskolan.module.scss';
import sP from './EventSearchInputField.Pahlmans.module.scss';

const styles = {
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const EventSearchInputField = ({
    q,
    api,
    modifier,
    type,
    suggestions,
    keywordHandler,
    qHandler,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const containerRef = useRef();

    const [currentQuery, setCurrentQuery] = useState(q);
    const [currentList, setCurrentList] = useState(suggestions);
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(suggestions.length > 0);

    useEffect(() => {
        const clickOutsideHandler = (e) => {
            if (containerRef && containerRef.current.contains(e.target)) {
                return;
            }
            setIsOpen(false);
        };
        document.addEventListener('mousedown', clickOutsideHandler);

        return () => {
            document.removeEventListener('mousedown', clickOutsideHandler);
        };
    }, []);

    const changeSelectHandler = (e) => {
        const value = e.currentTarget.value;
        setCurrentQuery(value);
        setIsOpen(false);

        // Make sure the search result is reloaded
        if(keywordHandler) {
            keywordHandler(value, true);
        }
    };

    const reloadSuggestions = debounce((value) => {
        if(!api) {
            return;
        }

        if(value !== '') {
            api += `?q=${encodeURIComponent(value)}`;
        }

        httpGet(api).then((result) => {
            if(!result.items || result.items.length === 0) {
                setCurrentList([]);
                setIsLoading(false);
                setIsOpen(false);
                return;
            }

            setCurrentList(result.items.map(i => i.text));
            setIsLoading(false);
            setIsOpen(true);
        });
    }, 500);

    const changeInputHandler = (e) => {
        const value = e.currentTarget.value;
        const reloadSuggest = value.length > 1;
        setCurrentQuery(value);
        if(value === '' || !reloadSuggest) {
            setCurrentList([]);
            setIsOpen(false);
        } else {
            setIsLoading(true);
        }

        // Search result doesn't need to reload
        if(qHandler) {
            qHandler(value);
        }

        // Only get list if string is long enough
        if(reloadSuggest) {
            reloadSuggestions(value);
        }
    };

    const highlightText = (text) => (
        text.replace(
            new RegExp(currentQuery, 'gi'),
            str => `<em>${str}</em>`
        )
    );

    const label = t([
        `eventSearchInputField.label.${type}`,
        'eventSearchInputField.label.default',
    ]);

    const classes = classNames(
        s['EventSearchInputField'],
        {[s[`EventSearchInputField--${modifier}`]]: modifier},
        {[s['EventSearchInputField--Open']]: isOpen},
        {[s['EventSearchInputField--Loading']]: isLoading},
    );

    return (
        <div className={classes} ref={containerRef}>
            <label
                className={s['EventSearchInputField__Container']}
                htmlFor="event_search_input_field"
            >
                <span className="sr-only">{label}</span>
                <input
                    id="event_search_input_field"
                    name="q"
                    value={currentQuery}
                    className={s['EventSearchInputField__Input']}
                    type="search"
                    placeholder={label}
                    autoComplete="off"
                    onChange={(e) => changeInputHandler(e)}
                />
                {!_.isEmpty(currentList) && !_.isEmpty(api) &&
                    <div className={s['EventSearchInputField__Suggest']}>
                        <ul className={s['EventSearchInputField__List']}>
                            {currentList.map((item, i) =>
                                <li
                                    className={s['EventSearchInputField__Item']}
                                    key={i}
                                >
                                    <input
                                        className={s['EventSearchInputField__Checkbox']}
                                        type="checkbox"
                                        id={`event_search_suggest_${i}`}
                                        name="suggest"
                                        value={item}
                                        onChange={(e) => changeSelectHandler(e)}
                                    />
                                    <label
                                        className={s['EventSearchInputField__Value']}
                                        htmlFor={`event_search_suggest_${i}`}
                                        dangerouslySetInnerHTML={{
                                            __html: highlightText(item)
                                        }}
                                    />
                                </li>
                            )}
                        </ul>
                    </div>
                }
            </label>
        </div>
    );
};

EventSearchInputField.defaultProps = {
    q: '',
    type: '',
    api: '',
    suggestions: [],
    modifier: '',
    qHandler: null,
    keywordHandler: null,
};

export default EventSearchInputField;
