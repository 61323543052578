/**
 * CardArticleHalf
 */

import React from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import ButtonLink from '../ButtonLink';
import Image from 'Components/Image';
import { useStyle } from 'Themes/theme';

import sD from './CardArticleHalf.Dansskolanentre.module.scss';
import sK from './CardArticleHalf.Kulturama.module.scss';
import sM from './CardArticleHalf.Medborgarskolan.module.scss';
import sP from './CardArticleHalf.Pahlmans.module.scss';
import sT from './CardArticleHalf.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const CardArticleHalf = ({
    tag,
    title,
    text,
    image,
    link,
    modifier,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const buttonLinkText = _.get(link, 'text', '');
    const buttonText = !_.isEmpty(buttonLinkText) ? buttonLinkText : t('cardArticleHalf.buttonDefault');

    let sizes = [
        '100vw',
    ];
    if(modifier !== 'Full') {
        sizes = [
            '(min-width: 1200px) 570px',
            '(min-width: 768px) 50vw',
        ];
    }

    const classes = classNames(
        s['CardArticleHalf'],
        {[s[`CardArticleHalf--${modifier}`]]: modifier},
        {[s['CardArticleHalf--NoImage']]: !image.src},
    );

    return (
        <article className={classes}>
            <a className={s['CardArticleHalf__Link']} href={link.href}>
                <span
                    className="sr-only"
                    dangerouslySetInnerHTML={{__html: buttonText}}
                />
            </a>
            <div className={s['CardArticleHalf__Container']}>
                <div className={s['CardArticleHalf__Image']}>
                    <Image
                        {...image}
                        sizes={sizes}
                        useCover={true}
                    />
                </div>
                <div className={s['CardArticleHalf__Content']}>
                    <div className={s['CardArticleHalf__ContentInner']}>
                        <div className={s['CardArticleHalf__Tag']}>{tag}</div>
                        <h3
                            className={s['CardArticleHalf__Title']}
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                        <p className={s['CardArticleHalf__Text']}>{text}</p>
                    </div>
                    <div className={s['CardArticleHalf__ButtonWrapper']}>
                        <ButtonLink
                            {...link}
                            text={buttonText}
                            modifiers={['Light']}
                            componentClass={s['CardArticleHalf__Button']}
                        />
                    </div>
                </div>
            </div>
        </article>
    );
};

CardArticleHalf.defaultProps = {
    tag: '',
    title: '',
    text: '',
    modifier: '',
    image: {},
    link: {},
};

export default CardArticleHalf;
