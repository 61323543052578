/**
 * Hero
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import EventSearchInput from '../EventSearchInput';
import Image from '../Image';
import Video from '../Video';
import Breadcrumbs from '../Breadcrumbs';
import ButtonLink from '../ButtonLink';
import { useStyle } from 'Themes/theme';

import sD from './Hero.Dansskolanentre.module.scss';
import sK from './Hero.Kulturama.module.scss';
import sM from './Hero.Medborgarskolan.module.scss';
import sP from './Hero.Pahlmans.module.scss';
import sT from './Hero.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Hero = ({
    title = '',
    image = {},
    text = '',
    eventSearchInput = {},
    modifier = '',
    breadcrumbs = {},
    video = '',
    button = {},
}) => {
    const [showVideo, setShowVideo] = useState(false);

    const s = useStyle(styles);

    const classes = classNames(
        s['Hero'],
        {[s[`Hero--${modifier}`]]: modifier },
        {[s['Hero--ShowVideo']]: showVideo },
        {[s['Hero--HasVideo']]: !_.isEmpty(video) },
    );

    if (!_.isEmpty(eventSearchInput) && !_.isEmpty(modifier)) {
        eventSearchInput.modifier = modifier;
    }

    return (
        <div className={classes}>
            {!_.isEmpty(video) && (
                <Video
                    showAsBackground={true}
                    url={video}
                    showVideo={() => {
                        if (!showVideo) {
                            setShowVideo(true);
                        }
                    }}
                    playerProps={{
                        quality: '4K',
                    }}
                />
            )}

            <div className={s['Hero__Background']}>
                <Image
                    className={s['Hero__Image']}
                    {...image}
                    sizes={['100vw']}
                    lazyLoad={false}
                    useCover={true}
                />
            </div>
            <div className={s['Hero__BreakoutBreadcrumbs']}>
                {
                    !_.isEmpty(breadcrumbs) && <Breadcrumbs {...breadcrumbs} /> // For school sites
                }
            </div>
            <div className={s['Hero__Container']}>
                <div className={s['Hero__TextContainer']}>
                    <div className={s['Hero__Breadcrumbs']}>
                        {
                            !_.isEmpty(breadcrumbs) && (
                                <Breadcrumbs {...breadcrumbs} />
                            ) // For school sites
                        }
                    </div>

                    {!_.isEmpty(text) && ( // For school sites
                        <p
                            className={s['Hero__Label']}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}

                    <h1
                        id='h1id'
                        className={s['Hero__Title']}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />

                    {!_.isEmpty(text) && ( // For Medborgarskolan
                        <p
                            className={s['Hero__Text']}
                            dangerouslySetInnerHTML={{ __html: text }}
                        />
                    )}
                </div>

                {!_.isEmpty(button) && (
                    <div className={s['Hero__Button']}>
                        <ButtonLink {...button} modifiers={['Primary']} />
                    </div>
                )}

                {!_.isEmpty(eventSearchInput) && (
                    <form
                        className={s['Hero__Search']}
                        method='get'
                        action={eventSearchInput.action}
                    >
                        <EventSearchInput {...eventSearchInput} />
                        <input type='hidden' name='sourceSearch' value='Hero' />
                    </form>
                )}
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string,
    image: PropTypes.object,
    text: PropTypes.string,
    eventSearchInput: PropTypes.object,
    modifier: PropTypes.string,
    breadcrumbs: PropTypes.object,
    video: PropTypes.string,
    button: PropTypes.object,
};

export default Hero;
