/**
 * SliderCardArticleHalf
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SlickSlider from 'Components/SlickSlider';
import CardArticleHalf from 'Components/CardArticleHalf';
import { useStyle } from 'Themes/theme';

import sM from './SliderCardArticleHalf.Medborgarskolan.module.scss';

const styles = {
    Medborgarskolan: sM,
};

const SliderCardArticleHalf = ({items, title, modifier, noTopSpacing}) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['SliderCardArticleHalf'],
        {[s[`SliderCardArticleHalf--${modifier}`]]: modifier},
        {[s['SliderCardArticleHalf--NoTopSpacing']]: noTopSpacing},
    );

    return (
        <div className={classes}>
            <div className={s['SliderCardArticleHalf__Container']}>
                <SlickSlider
                    Component={CardArticleHalf}
                    items={items}
                    title={title}
                    mobileInset="30px"
                    size="Half"
                />
            </div>
        </div>
    );
};

SliderCardArticleHalf.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
    modifier: PropTypes.string,
    noTopSpacing: PropTypes.bool,
};

SliderCardArticleHalf.defaultProps = {
    items: [],
    title: '',
    modifier: '',
    noTopSpacing: false,
};

export default SliderCardArticleHalf;
